<template>
    <div v-if="showModal" :class="['main-category-select-modal modal',showModal?'modal-open':'']" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">Будь ласка, оберіть категорію</h3>
                <p class="modal-content__info">Так ви зможете знайти саме ті пропозиції, які вам потрібні.</p>

                <div class="main-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[0])">
                                <div class="card bg-jade">
                                    <div class="card__img">
                                        <img src="/assets/icons/beruf.png" alt="Навчання та підвищення кваліфікації">
                                    </div>
                                    <div class="card__content">
                                        <h3>Навчання та підвищення кваліфікації</h3>
                                        <p>Знайдіть пропозиції щодо професійного навчання та підвищення кваліфікації.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[1])">
                                <div class="card bg-meganta">
                                    <div class="card__img">
                                        <img src="/assets/icons/hilfe.png" alt="Поради та допомога">
                                    </div>
                                    <div class="card__content">
                                        <h3>Поради та допомога</h3>
                                        <p>Знаходьте підтримку в будь-яких ситуаціях.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[2])">
                                <div class="card bg-blue">
                                    <div class="card__img">
                                        <img src="/assets/icons/freizeit.png" alt="Вільний час">
                                    </div>
                                    <div class="card__content">
                                        <h3>Вільний час</h3>
                                        <p>Знайдіть ідеї для свого дозвілля.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>